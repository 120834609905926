<template>
    <b-card title="Edit Base Warranty">
        <b-col cols="12">
            <label>Name</label>
            <b-form-input placeholder="Project Name" v-model="baseWarranty.name"></b-form-input>
        </b-col>

        <b-col cols="12">
            <label>Duration (Month)</label>
            <b-form-input placeholder="Extend Month" v-model="baseWarranty.month"></b-form-input>
        </b-col>

        <b-col cols="12" class="mt-2">
            <b-button variant="success" @click="doUpdateBaseWarranty">Update</b-button>
        </b-col>
    </b-card>
</template>

<script>
import { mapActions } from 'vuex';
import { sleep }from '@/utils/utils.js'
export default {
    data() {
        return {
            baseWarranty: {},
        }
    },
    mounted() {
        this.getBaseWarranty().then((data) => {
            // // BAD !!!
            // let id = this.$route.params.id;
            // let warranty = data.filter((w) => {
            //     if (w._id === id) {
            //         return true;
            //     }

            //     return false;
            // })

            this.baseWarranty = data[0];
        })
    },
    methods: {
        ...mapActions({
            getBaseWarranty: 'warranty/getBaseWarranty',
            updateBaseWarranty: 'warranty/updateBaseWarranty'
        }),
        doUpdateBaseWarranty() {
            this.updateBaseWarranty(this.baseWarranty).then(async () => {
                this.$bvToast.toast("Successfully Updated Base Warranty", {
                    title: "Success",
                    variant: "success",
                    solid: true,
                });
                await sleep(1000);
                this.$router.go(-1);
            }).catch((e) => {
                console.error(e)
                this.$bvToast.toast(`Update Base Warranty Failed`, {
                    title: "Fail",
                    variant: "danger",
                    solid: true,
                });
            })
        }
    }
}
</script>